import { request, getHost } from 'src/api';
import AuthService from './auth';

export default class ApplicationService {
  static async get() {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/application`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }
}
