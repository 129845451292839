export const AUTH_TOKEN_KEY = 'token';

export const LIMIT = 30;

export const CUSTOMER_DEPARTMENT_ID = '200000000000000000000000';
export const NO_DEPARTMENT_ID = '000000000000000000000001';


export const ACCOUNT_STATUS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate'
};


export const ACCOUNT_STATUS_TXT_L = {
  activate: '正常',
  deactivate: '停用'
};

export const ACCOUNT_STATUS_TXT = {
  ACTIVATE: '正常',
  DEACTIVATE: '停用'
};

export const PERMISSION_TYPE = {
  MANAGER: 'manager',
  NORMAL: 'normal'
};

export const PERMISSION_TYPE_TXT_L = {
  manager: '管理者',
  normal: '一般使用者'
};

export const PERMISSION_TYPE_TXT = {
  MANAGER: '管理者',
  NORMAL: '一般使用者'
};

export const USER_ORDER = {
  USER_TYPE: 'userType',
  DEPARTMENT: 'department',
  PERMISSION_TYPE: 'permissionType',
  ACCOUNT_STATUS: 'accountStatus',
  MODIFIED_DATE: 'modifiedDate'
};

export const DELETED_USER_ORDER = {
  USER_TYPE: 'userType',
  DEPARTMENT: 'department',
  PERMISSION_TYPE: 'permissionType',
  DELETED_DATE: 'deletedDate'
};

export const USER_TYPE = {
  // SUPER_ADMIN: 'superAdmin',
  USER: 'user',
  CUSTOMER: 'customer'
};

export const USER_TYPE_TXT_L = {
  superAdmin: '超級管理者',
  user: '內部人員',
  customer: '客戶'
};

export const USER_TYPE_TXT = {
  SUPER_ADMIN: '超級管理者',
  USER: '內部人員',
  CUSTOMER: '客戶'
};


