import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { Modal } from 'antd';
import UserService from 'src/services/user';
import { ACCOUNT_STATUS } from 'src/constants';

export default class UserItem {
  creator = null;
  type = null;
  id = '';

  @observable name = '';
  @observable nickname = '';
  @observable phone = '';
  @observable phoneExt = '';
  @observable permission = '';
  @observable department = '';
  @observable modifiedDate = '';
  @observable status = '';
  @observable customerBelong = [];
  @observable customerBelongUI = [];
  @observable applications = [];
  @observable isLoading = false;

  informDelete = () => {}

  constructor(item, informDelete = () => {}) {
    makeObservable(this);

    runInAction(() => {
      this.creator = item.creator;
      this.customerBelong = item.customerBelong;
      this.department = item.department;
      this.email = item.email;
      this.id = item.id;
      this.modifiedDate = item.modifiedDate;
      this.modifier = item.modifier;
      this.name = item.name;
      this.nickname = item.nickname;
      this.permission = item.permission;
      this.phone = item.phone;
      this.phoneExt = item.phoneExt;
      this.status = item.status;
      this.type = item.type;
    });

    this.informDelete = informDelete;
  }

  getCurtomerBelong = async () => {
    if (this.isLoading) return;

    runInAction(() => { this.isLoading = true; });

    try {
      const res = await UserService.customerBelong(this.id);
      runInAction(() => {
        this.customerBelongUI = res;
        this.isLoading = false;
      });
    } catch {
      runInAction(() => { this.isLoading = false; });
    }
  }


  getApplications = async () => {
    if (this.isLoading) return;

    runInAction(() => { this.isLoading = true; });


    try {
      const res = await UserService.applications(this.id);
      runInAction(() => {
        this.applications = res;
        this.isLoading = false;
      });
    } catch {
      runInAction(() => { this.isLoading = false; });
    }

  }

  deactivate = async () => {
    Modal.confirm({
      title: `確定要將使用者「 ${this.name} 」設為停用？`,
      content: '停用後，此帳號將無法登入任何系統，但在子系統的資料會被保留，是否確定停用?',
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        try {
          await UserService.deactivate(this.id);
          runInAction(() => { this.status = ACCOUNT_STATUS.DEACTIVATE; });
          Modal.success({ title: `已將使用者「 ${this.name} 」設為停用。`, okText: '確定' });
        } catch {
          Modal.error({ okText: '確認', title: '發生錯誤，無法停用' });
        }
      }
    });
  }

  activate = async (data) => {
    const { name } = data;
    return new Promise((resolve) => (
      Modal.confirm({
        title: `確定要將使用者「 ${name} 」設為啟用？`,
        okText: '確定',
        cancelText: '取消',
        onOk: async () => {
          try {
            await UserService.update(this.id, data);
            await UserService.activate(this.id);
            this.informDelete(this.id);
            Modal.success({ title: `已重新啟用使用者「${name}」。`, okText: '確定' });
            resolve();
          } catch {
            Modal.error({ okText: '確認', title: '發生錯誤，無法停用' });
          }
        }
      })
    ));
  }

  update = async (data) => {
    try {
      await UserService.update(this.id, data);
      const res = await UserService.getUserByIds([this.id]);
      const userData = res[0];
      runInAction(() => {
        this.customerBelong = userData.customerBelong;
        this.department = userData.department;
        this.modifiedDate = userData.modifiedDate;
        this.modifier = userData.modifier;
        this.name = userData.name;
        this.nickname = userData.nickname;
        this.permission = userData.permission;
        this.phone = userData.phone;
        this.phoneExt = userData.phoneExt;
        this.status = userData.status;
        this.type = userData.type;
      });
    } catch (err) {
      switch (err.response.status) {
        case 403:
          Modal.error({ okText: '確認', title: '無權更新' });
          break;
        default:
          Modal.error({ okText: '確認', title: '發生錯誤，無法更新' });
      }
    }
  }

  delete = async () => {
    Modal.confirm({
      title: `確定要刪除使用者「 ${this.name} 」？`,
      content: '此帳號在子系統的資料皆會被刪除，確定要刪除？',
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        try {
          await UserService.delete(this.id);
          this.informDelete(this.id);
        } catch {
          Modal.error({ okText: '確認', title: '發生錯誤，無法刪除' });
        }
      }
    });

  }
}
