import React, { useState } from 'react';
import { observer, Observer } from 'mobx-react';
import { Input, Divider, Button, Row, Tooltip } from 'antd';
import Table from 'src/components/VirtualTable';
import dayjs from 'dayjs';
import ReactResizeDetector from 'react-resize-detector';
import { USER_TYPE_TXT_L, USER_TYPE, PERMISSION_TYPE_TXT_L, DELETED_USER_ORDER } from 'src/constants';
import sortActive from 'src/assets/sortActive.png';
import sortDisabled from 'src/assets/sortDisabled.png';

import styles from './styles.module.css';

const Deleted = observer((props) => {
  const [columns] = useState(() => ([
    {
      title: (
        <Observer>
          {() => (
            <Row justify="center" align="middle">
              <div>身份</div>
              <div
                className={styles.icon}
                onClick={() => props.vm.onOrderChange(DELETED_USER_ORDER.USER_TYPE)}
              >
                {props.vm?.order === DELETED_USER_ORDER.USER_TYPE
                  ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                  : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
              </div>
            </Row>
          )}
        </Observer>
      ),
      key: 'permission',
      width: 140,
      showSorterTooltip: true,
      render: (data) => (<Row justify="center">{USER_TYPE_TXT_L[data.type]}</Row>)
    },
    {
      title: (
        <Observer>
          {() => (
            <Row justify="center" align="middle">
              <div>部門(內部人員用)</div>
              <div
                className={styles.icon}
                onClick={() => props.vm.onOrderChange(DELETED_USER_ORDER.DEPARTMENT)}
              >
                {props.vm?.order === DELETED_USER_ORDER.DEPARTMENT
                  ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                  : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
              </div>
            </Row>
          )}
        </Observer>
      ),
      key: 'department',
      width: 150,
      render: (data) => (
        <Row justify="center">
          {data.type !== USER_TYPE.CUSTOMER
            ? <div className={styles.txt}>{data.department}</div>
            : <div>-</div>}
        </Row>
      )
    },
    {
      title: (
        <Observer>
          {() => (
            <Row justify="center" align="middle">
              <div>本系統權限</div>
              <div
                className={styles.icon}
                onClick={() => props.vm.onOrderChange(DELETED_USER_ORDER.PERMISSION_TYPE)}
              >
                {props.vm?.order === DELETED_USER_ORDER.PERMISSION_TYPE
                  ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                  : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
              </div>
            </Row>
          )}
        </Observer>
      ),
      key: 'type',
      width: 140,
      render: (data) => (<Row justify="center">{PERMISSION_TYPE_TXT_L[data.permission]}</Row>)
    },
    {
      title: <Row justify="center">Email(帳號)</Row>,
      key: 'email',
      width: 180,
      render: (data) => (
        <Row justify="center">
          <div className={styles.txt}>
            {data.email}
          </div>
        </Row>
      )
    },
    {
      title: <Row justify="center">使用者姓名</Row>,
      key: 'name',
      width: 150,
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              <div className={styles.txt}>
                {data.name}
              </div>
            </Row>
          )}
        </Observer>
      )
    },
    {
      title: <Row justify="center">所屬部門(客戶專用)</Row>,
      key: 'customerBelong',
      width: 150,
      render: (data) => (
        <Row justify="center">
          {data.type === USER_TYPE.CUSTOMER
            ? (
              <Tooltip
                color="white"
                onMouseEnter={data.getCurtomerBelong}
                title={(
                  <div
                    style={{ color: 'black', width: 150 }}
                  >
                    <div>所屬部門(客戶專用)</div>
                    <Divider style={{ margin: '5px 0' }} />
                    <>
                      {data.customerBelong.map((a, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${a}-${index}`}>{a}</div>
                      ))}
                    </>
                  </div>
              )}
              >
                <Button type="link">{'> more ...'}</Button>
              </Tooltip>
            )
            : <div>-</div>}
        </Row>
      )
    },
    {
      title: <Row justify="center">可使用系統</Row>,
      key: 'applications',
      width: 120,
      render: (data) => (
        <Row justify="center">
          <Tooltip
            color="white"
            onMouseEnter={data.getApplications}
            title={(
              <div
                style={{ color: 'black', width: 150 }}
              >
                <div>可使用系統</div>
                <Divider style={{ margin: '5px 0' }} />
                <>
                  {data.applications.map((a, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`${a}-${index}`}>{a}</div>
                  ))}
                </>
              </div>
      )}
          >
            <Button type="link">{'> more ...'}</Button>
          </Tooltip>
        </Row>
      )
    },
    {
      title: (
        <Observer>
          {() => (
            <Row justify="center" align="middle">
              <div>停用時間</div>
              <div
                className={styles.icon}
                onClick={() => props.vm.onOrderChange(DELETED_USER_ORDER.DELETED_DATE)}
              >
                {props.vm?.order === DELETED_USER_ORDER.DELETED_DATE
                  ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                  : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
              </div>
            </Row>
          )}
        </Observer>
      ),
      key: 'deletedDate',
      width: 170,
      render: (data) => (
        <Row justify="center">
          {data.deletedDate ? dayjs(data.deletedDate).format('YYYY-MM-DD HH:mm') : ''}
        </Row>
      )
    }
  ]));


  return (
    <div>
      <Input.Search
        placeholder="Email、使用者姓名"
        onSearch={props.vm.onSearch}
        style={{ marginBottom: 10, width: 600 }}
      />

      <ReactResizeDetector handleWidth handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <div style={{ width: 1200, height: 'calc(100vh - 170px)' }} id="deletedTable">
                <Table
                  size="small"
                  rowKey="_id"
                  onFetch={() => {
                    if (props.vm.anchor) props.vm.getList();
                  }}
                  columns={columns}
                  scroll={{ y: height, x: 1200 }}
                  dataSource={props.vm.list}
                />
              </div>
            )}
          </Observer>
        )}
      </ReactResizeDetector>
    </div>
  );
});

export default Deleted;
