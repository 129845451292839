import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import DepartmentService from 'src/services/department';
import { errorModal } from 'src/utils';

export default class DepartmentItem {
  id = '';
  defaultDepartment = false;
  @observable phone = '';
  @observable name = '';
  @observable modifiedDate = '';

  @observable isEdit = false;
  @observable editPhone = '';
  @observable editName = '';

  departmentPaneVM = null;

  constructor(item, departmentPaneVM) {
    makeObservable(this);
    this.departmentPaneVM = departmentPaneVM;

    runInAction(() => {
      this.id = item._id;
      this.defaultDepartment = item.defaultDepartment;
      this.phone = item.phone;
      this.name = item.name;
      this.modifiedDate = item.modifiedDate;
    });
  }

  update = async () => {
    try {
      runInAction(() => { this.isEdit = false; });

      await DepartmentService.update(
        this.id,
        {
          phone: this.editPhone,
          name: this.editName
        }
      );

      runInAction(() => {
        this.name = this.editName;
        this.phone = this.editPhone;
        this.modifiedDate = dayjs().toISOString();
      });

      this.departmentPaneVM.mainPageVM.informUserPaneReload();

    } catch {
      errorModal('發生錯誤，無法更新部門');
    }
  }

  @action
  onEdit = () => {
    this.isEdit = true;
    this.editPhone = this.phone;
    this.editName = this.name;
  }

  @action
  onEditCancel = () => {
    this.isEdit = false;
  }

  @action
  onNameChange = (e) => { this.editName = e.target.value; }

  @action
  onPhoneChange = (e) => { this.editPhone = e.target.value; }

}
