import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Form, Input, Col, Row, Select, Button } from 'antd';
import { PERMISSION_TYPE, PERMISSION_TYPE_TXT, ACCOUNT_STATUS, ACCOUNT_STATUS_TXT, USER_TYPE, USER_TYPE_TXT, CUSTOMER_DEPARTMENT_ID } from 'src/constants';

import styles from './styles.module.css';

const SearchBar = observer((props) => {
  const [form] = Form.useForm();


  const onSearch = () => {
    const formData = form.getFieldsValue();

    const data = {
      ...formData,
      customerBelong: formData.customerBelong?.length > 0 ? formData.customerBelong : null
    };

    props.onSearch(data);
  };

  const onClear = () => {
    form.resetFields();
  };


  return (
    <Form form={form} className={styles.box}>

      <div className={styles.rowOne}>
        <div>身份：</div>
        <Col className={clsx([styles.flexOne, styles.mr])}>
          <Form.Item name="type" className={styles.formItem}>
            <Select allowClear>
              {Object.keys(USER_TYPE).map((u) => (
                <Select.Option key={u} value={USER_TYPE[u]}>{USER_TYPE_TXT[u]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <div>部門(內部人員用)：</div>
        <Col className={clsx([styles.flexOne, styles.mr])}>
          <Form.Item name="department" className={styles.formItem}>
            <Select allowClear>
              {props.innerDepartment
                .filter((d) => d._id !== CUSTOMER_DEPARTMENT_ID)
                .map((d) => (
                  <Select.Option key={d._id} value={d._id}>{d.name}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>


        <div>權限：</div>
        <Col className={clsx([styles.flexOne, styles.mr])}>
          <Form.Item name="permission" className={styles.formItem}>
            <Select allowClear>
              {Object.keys(PERMISSION_TYPE).map((d) => (
                <Select.Option key={d} value={PERMISSION_TYPE[d]}>{PERMISSION_TYPE_TXT[d]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <div>狀態：</div>
        <Col className={styles.flexOne}>
          <Form.Item name="status" className={styles.formItem}>
            <Select allowClear>
              {Object.keys(ACCOUNT_STATUS).map((d) => (
                <Select.Option key={d} value={ACCOUNT_STATUS[d]}>{ACCOUNT_STATUS_TXT[d]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </div>


      <Row>
        <Col flex={1} className={styles.mr}>
          <Form.Item label="所屬部門(客戶專用)" name="customerBelong">
            <Select allowClear mode="multiple">
              {props.clientDepartment.map((d) => (
                <Select.Option key={d._id} value={d._id}>{d.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1} className={styles.mrl}>
          <Form.Item label="自定義搜尋" name="keyword">
            <Input placeholder="Email、使用者姓名" />
          </Form.Item>
        </Col>
        <Col className={styles.btn}>
          <Form.Item>
            <Button onClick={onClear} className={styles.mrs}>清空篩選條件</Button>
            <Button type="primary" onClick={onSearch}>套用搜尋</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export default SearchBar;
