import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Modal, Row, Col } from 'antd';
import useAppStateContext from 'src/stores/global/useAppStateContext';
import { USER_TYPE_TXT_L, PERMISSION_TYPE_TXT_L } from 'src/constants';
import MeService from 'src/services/me';


const UpdateProfileModal = observer((props) => {
  const { state, actions } = useAppStateContext();
  const [form] = Form.useForm();

  const hide = (e) => {
    form.resetFields();
    props.modal.hide();
  };

  const submit = async () => {
    try {
      await form.validateFields();
      const data = form.getFieldsValue();
      await MeService.update(data);

      props.modal.informUserPaneReload();
      hide();

      const p = await MeService.getProfile();
      actions.updateProfile(p);
    } catch {
      // ignore
    }
  };

  useEffect(() => {
    if (props.modal.visible) {
      form.setFieldsValue({
        name: props.profile.name,
        nickname: props.profile.nickname,
        phone: props.profile.phone,
        phoneExt: props.profile.phoneExt
      });
    }

  }, [form, props.modal.visible, props.profile]);

  return (
    <Modal
      visible={props.modal.visible}
      title="編輯使用者"
      okText="儲存"
      cancelText="取消"
      onCancel={hide}
      onOk={submit}
      width={650}
      maskClosable={false}
    >

      <Form form={form} autoComplete={false}>
        <Form.Item label="身份">
          {USER_TYPE_TXT_L[props.profile.type]}
        </Form.Item>

        <Form.Item label="部門">
          {props.profile.department?.name}
        </Form.Item>

        <Form.Item label="本系統權限">
          {PERMISSION_TYPE_TXT_L[props.profile.permission]}
        </Form.Item>

        <Row>
          <Col style={{ marginRight: 20 }}>
            <Form.Item
              label="部門電話"
              name="phone"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="#分機" name="phoneExt">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={{ marginRight: 20 }}>
            <Form.Item
              label="使用者姓名"
              name="name"
              rules={[{ required: true, message: '此欄必填!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="暱稱" name="nickname">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Email(帳號)">
          {props.profile.email}
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default UpdateProfileModal;
