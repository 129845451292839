import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

import { errorModal } from 'src/utils';
import { LIMIT, USER_ORDER, CUSTOMER_DEPARTMENT_ID, NO_DEPARTMENT_ID } from 'src/constants';
import UserService from 'src/services/user';
import DepartmentService from 'src/services/department';
import ModalViewModel from 'src/viewModels/modal';
import DeletedModalViewModel from 'src/viewModels/UserPane/DeletedModal';
import UserItem from './UserItem';

export default class UserPane {
  @observable list = [];
  @observable anchor = null;
  @observable order = USER_ORDER.USER_TYPE;
  @observable isLoading = false;
  @observable _departments = [];
  @observable searchConditions = null;

  modal = new ModalViewModel();
  deletedModalVm = new DeletedModalViewModel(() => this._reload());

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    Promise.all([
      this.getList(),
      this.getDepartment()
    ]);
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });


      const { users, anchor } = await UserService.get({
        ...this.searchConditions,
        limit: LIMIT,
        anchor: this.anchor,
        order: this.order
      });

      const list = users.map((u) => new UserItem(u));

      runInAction(() => {
        this.list = [...this.list, ...list];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法獲取使用者列表');
    }
  }

  getDepartment = async () => {
    try {
      const res = await DepartmentService.get();
      runInAction(() => { this._departments = res; });
    } catch {
      errorModal('發生錯誤，無法獲取部門列表');
    }
  }

  create = async (data) => {
    try {
      await UserService.create(data);
      this.list = [];
      this.anchor = null;
      this.getList();

    } catch (err) {
      switch (err.response.status) {
        case 403:
          errorModal('無權新增');
          break;
        case 409:
          errorModal('此帳號（email）已存在');
          break;
        default:
          errorModal('發生錯誤，無法更新');
      }
    }
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this._reload();
  }

  @action
  onOrderChange = (newOrder) => {
    if (newOrder === this.order) {
      this.order = USER_ORDER.USER_TYPE;
    } else {
      this.order = newOrder;
    }
    this._reload();
  }

  @computed
  get innerDepartment() {
    return this._departments;
  }

  @computed
  get clientDepartment() {
    return this._departments.filter((d) => d._id !== NO_DEPARTMENT_ID && d._id !== CUSTOMER_DEPARTMENT_ID);
  }

  @computed
  get departmentPhoneIdMap() {
    const map = new Map();

    this._departments.forEach((d) => {
      map.set(d._id, d.phone);
    });

    return map;
  }

  @action
  _reload = () => {
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  @action
  reload = () => {
    this.getDepartment();
    this._reload();
  }
}
