import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Form, Input, Select, Row, Col } from 'antd';

import { USER_TYPE, USER_TYPE_TXT, PERMISSION_TYPE, PERMISSION_TYPE_TXT, USER_TYPE_TXT_L, CUSTOMER_DEPARTMENT_ID, PERMISSION_TYPE_TXT_L, ACCOUNT_STATUS } from 'src/constants';
import ModalViewModel from 'src/viewModels/modal';

import styles from './styles.module.css';

const ActionModal = observer((props) => {
  const [form] = Form.useForm();
  const [type, setType] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const hide = (e) => {
    form.resetFields();
    props.modal.hide();
  };

  // set initial values
  useEffect(() => {
    const data = props.modal.data;

    if (data) {
      // set inital value
      form.setFieldsValue({
        type: data.type,
        action_department: data.department.id,
        action_customerBelong: data.customerBelong,
        action_permission: data.permission,
        phone: data.phone,
        phoneExt: data.phoneExt,
        name: data.name,
        nickname: data.nickname,
        email: data.email
      });
      setType(data.type);
      setIsEdit(true);

    } else if (props.modal.visible) {
      form.setFieldsValue({ type: USER_TYPE.USER });
      setType(USER_TYPE.USER);
      setIsEdit(false);
    }
  }, [form, props.modal.data, props.modal.visible]);

  const submit = async () => {
    try {
      // validate, will throw error
      await form.validateFields();

      // get data
      const formData = form.getFieldsValue();
      const data = {
        name: formData.name,
        nickname: formData.nickname || undefined,
        email: formData.email,
        phone: formData.phone || '',
        phoneExt: formData.phoneExt || undefined,
        type: formData.type,
        permission: formData.action_permission,
        password: formData.password || undefined,
        department: type === USER_TYPE.CUSTOMER ? CUSTOMER_DEPARTMENT_ID : formData.action_department,
        customerBelong: formData.action_customerBelong
      };

      if (isEdit) {
        // update
        delete data.email;

        if (props.modal.data.status === ACCOUNT_STATUS.DEACTIVATE) {
          await props.modal.data.activate(data);
        } else {
          await props.modal.data.update(data);
        }
      } else {
        // create
        await props.create(data);
      }

      hide();
    } catch (err) {
      // ignore
    }
  };

  const onInnerDepartmentChange = (departmentId) => {
    if (!form.getFieldValue('phone') || !isTouched) {
      const phone = props.departmentPhoneIdMap.get(departmentId);
      form.setFieldsValue({ phone });
    }
  };


  const onTypeChange = (userType) => {
    setType(userType);
    if (userType === USER_TYPE.CUSTOMER) {
      form.setFieldsValue({ action_permission: PERMISSION_TYPE.NORMAL });
    }
  };

  const txt = () => {
    if (!isEdit) return '新增';
    if (props.modal.data?.status === ACCOUNT_STATUS.DEACTIVATE) return '啟用';
    return '儲存';
  };

  return (
    <Modal
      centered
      title={isEdit ? '編輯使用者' : '新增使用者'}
      visible={props.modal.visible}
      onCancel={hide}
      onOk={submit}
      width={700}
      okText={txt()}
      cancelText="取消"
      maskClosable={false}
      zIndex={1000}
    >
      <Form
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="身份"
          name="type"
          rules={[{ required: true, message: '此欄必填!' }]}
        >
          {isEdit
            ? USER_TYPE_TXT_L[props.modal.data?.type]
            : (
              <Select onChange={onTypeChange}>
                {Object.keys(USER_TYPE).map((t) => (
                  <Select.Option key={t} value={USER_TYPE[t]}>{USER_TYPE_TXT[t]}</Select.Option>
                ))}
              </Select>
            )}
        </Form.Item>


        {(type === USER_TYPE.USER) && (
        <Form.Item
          label="部門(內部人員用)"
          name="action_department"
          rules={[{ required: true, message: '此欄必填!' }]}
        >
          <Select
            onChange={onInnerDepartmentChange}
          >
            {props.clientDepartment.map((d) => (
              <Select.Option key={d._id} value={d._id}>{d.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        )}

        {(type === USER_TYPE.CUSTOMER) && (
        <Form.Item
          label="所屬部門(客戶專用)"
          name="action_customerBelong"
          rules={[{ required: true, message: '此欄必填!' }]}
        >
          <Select mode="multiple">
            {props.clientDepartment.map((d) => (
              <Select.Option key={d._id} value={d._id}>{d.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        )}

        <Form.Item
          label="本系統權限"
          name="action_permission"
          rules={[{ required: true, message: '此欄必填!' }]}
        >
          {(isEdit && type === USER_TYPE.CUSTOMER)
            ? PERMISSION_TYPE_TXT_L[props.modal.data?.permission]
            : (
              <Select disabled={(type === USER_TYPE.CUSTOMER) && !isEdit}>
                {Object.keys(PERMISSION_TYPE).map((d) => (
                  <Select.Option key={d} value={PERMISSION_TYPE[d]}>{PERMISSION_TYPE_TXT[d]}</Select.Option>
                ))}
              </Select>
            )}
        </Form.Item>

        {(type === USER_TYPE.USER) && (
        <Row>
          <Col style={{ marginRight: 20 }}>
            <Form.Item
              label="部門電話"
              name="phone"
              extra="依選取部門自動帶入，分機沒有可不填"
              rules={[{ max: 30, message: '電話過長!' }]}
            >
              <Input onChange={() => { setIsTouched(true); }} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="#分機"
              name="phoneExt"
              rules={[{ max: 10, message: '分機過長!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        )}


        <Row>
          <Col style={{ marginRight: 20 }}>
            <Form.Item
              label="使用者姓名"
              name="name"
              extra="客戶請填單位全稱，暱稱可不填"
              rules={[{ required: true, max: 50, message: '使用者姓名過長!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="暱稱"
              name="nickname"
              rules={[{ max: 50, message: '暱稱過長!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Email(帳號)"
          name="email"
          rules={[{ required: true, message: 'email格式有誤!', type: 'email' }]}
        >
          {isEdit ? props.modal.data?.email : <Input autoComplete="off" />}
        </Form.Item>

        <Form.Item
          label="密碼"
          name="password"
          rules={[{ required: !props.modal.data, message: '密碼至少8字元!', min: 8 }]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <Form.Item
          label="重新輸入密碼"
          name="confirm"
          dependencies={['password']}
          rules={[
            {
              required: !props.modal.data,
              message: '請重新輸入密碼!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const password = getFieldValue('password');

                if (password && password !== value) {
                  return Promise.reject(new Error('與密碼不相符!'));
                }

                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>

      </Form>

      {isEdit && (
        <div className={styles.container}>
          <div className={styles.content}>
            <Row>
              <div>最後更新時間：</div>
              <div>{dayjs(props.modal.data?.modifiedDate).format('YYYY-MM-DD HH:mm')}</div>
            </Row>
            <Row>
              <div>建立者：</div>
              <div>{props.modal.data?.creator?.name ?? ''}</div>
            </Row>
            <Row>
              <div>修改者（最後一次修改異動的人）：</div>
              <div>{props.modal.data?.modifier?.name ?? ''}</div>
            </Row>
          </div>
        </div>
      )}
    </Modal>
  );
});

ActionModal.propTypes = {
  modal: PropTypes.instanceOf(ModalViewModel).isRequired,
  create: PropTypes.func.isRequired
};

export default ActionModal;
