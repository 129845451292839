import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Modal } from 'antd';


const CreateModal = observer((props) => {
  const [form] = Form.useForm();

  const hide = (e) => {
    form.resetFields();
    props.modal.hide();
  };

  const submit = async () => {
    try {
      await form.validateFields();
      const data = form.getFieldsValue();
      props.create(data);
      form.resetFields();
    } catch {
      // ignore
    }
  };

  return (
    <Modal
      visible={props.modal.visible}
      title="新增部門"
      okText="新增"
      cancelText="取消"
      onCancel={hide}
      onOk={submit}
      maskClosable={false}
    >
      <Form form={form} autoComplete={false}>
        <Form.Item
          label="部門名稱"
          name="name"
          rules={[{ required: true, message: '此欄必填!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="部門電話"
          name="phone"
          help="沒有可不填"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default CreateModal;
