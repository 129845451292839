import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Modal, message } from 'antd';
import MeService from 'src/services/me';
import withRouter from 'src/components/withRouter';
import { errorModal } from 'src/utils';


const CangePasswordModal = observer((props) => {
  const [form] = Form.useForm();

  const hide = (e) => {
    form.resetFields();
    props.modal.hide();
  };

  const changePassword = async (password) => {
    try {
      await MeService.changePassword(password);
    } catch {
      errorModal('發生錯誤，無法更新密碼');
    }
  };

  const submit = async () => {
    try {
      await form.validateFields();
      const { password } = form.getFieldsValue();
      await changePassword(password);
      hide();
      MeService.logout();
      message.success('已重設密碼，請以新密碼重新登入');
      props.router?.navigate('/login');
    } catch {
      // ignore
    }
  };

  return (
    <Modal
      visible={props.modal.visible}
      title="變更密碼"
      okText="儲存"
      cancelText="取消"
      onCancel={hide}
      onOk={submit}
      maskClosable={false}
    >
      <Form form={form} autoComplete={false}>
        <Form.Item
          label="新密碼"
          name="password"
          rules={[{
            required: true,
            message: '密碼須包含英文與數字共八碼以上',
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
          }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="再次輸入新密碼"
          name="confirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: '此欄必填!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('與密碼不相符!'));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default withRouter(CangePasswordModal);
