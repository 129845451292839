import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Avatar, Layout, Dropdown, Menu, Tabs } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { PERMISSION_TYPE } from 'src/constants';
import MainPageVM from 'src/viewModels/MainPageVM';
import MeService from 'src/services/me';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import UserPane from './UserPane';
import DepartmentPane from './DepartmentPane';
import ApplicationPane from './ApplicationPane';
import ChangePasswordModal from './modals/ChangePassword';
import UpdateProfileModal from './modals/UpdateProfile';

import styles from './styles.module.css';

const { Header, Content } = Layout;

@observer
class MainPage extends React.Component {
  constructor() {
    super();
    this.vm = new MainPageVM();
  }

  onMenuClick = ({ key }) => {
    switch (key) {
      case 'update_profile':
        return this.vm.updateProfileModalVM.show();

      case 'change_password':
        return this.vm.changePasswordModalVM.show();

      case 'logout':
        MeService.logout();
        return this.props.router?.navigate('/login');

      default:
        return null;
    }
  }


  render() {
    return (
      <div className={styles.page}>
        <Layout>
          <Header className={styles.header}>
            <div
              className={styles.headerLeft}
              style={{ cursor: 'pointer' }}
              onClick={() => window.location.reload()}
            >
              i-Buzz 帳號管理系統
            </div>

            <Dropdown
              arrow
              overlay={(
                <Menu onClick={this.onMenuClick}>
                  <Menu.Item key="update_profile">修改使用者資料</Menu.Item>
                  <Menu.Item key="change_password">變更密碼</Menu.Item>
                  <Menu.Item key="logout">登出</Menu.Item>
                </Menu>
              )}
            >
              <div className={styles.headerRight}>
                <Avatar size="default" icon={<UserOutlined />} className={styles.avatar} />
                {`${this.props.profile.name} ${this.props.profile.nickname ? `(${this.props.profile.nickname})` : ''}`}
              </div>
            </Dropdown>
          </Header>


          <Content className={styles.content}>
            <Tabs defaultActiveKey="1">
              {this.props.profile.permission === PERMISSION_TYPE.MANAGER && (
                <>
                  <Tabs.TabPane tab="使用者列表" key="1">
                    <UserPane vm={this.vm.userPaneVM} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="部門設定" key="2">
                    <DepartmentPane vm={this.vm.departmentPaneVM} />
                  </Tabs.TabPane>
                </>
              )}
              <Tabs.TabPane tab="應用系統列表" key="3">
                <ApplicationPane vm={this.vm.applicationPaneVM} />
              </Tabs.TabPane>
            </Tabs>

            <ChangePasswordModal
              modal={this.vm.changePasswordModalVM}
            />
            <UpdateProfileModal
              modal={this.vm.updateProfileModalVM}
              profile={this.props.profile}
            />

          </Content>
        </Layout>
      </div>
    );
  }
}

MainPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  profile: PropTypes.shape({
    name: PropTypes.string,
    nickname: PropTypes.string
  }).isRequired
};

export default withProfile(withRouter(MainPage), true);
