import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, Row, Button, Input } from 'antd';

import CreateModal from './CreateModal';
import DeleteModal from './DeleteModal';

import styles from './styles.module.css';

@observer
class DepartmentPane extends React.Component {

  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  componentDidMount() {
    this.vm.didMount();
  }

  columns = [
    {
      title: <Row justify="center">部門名稱</Row>,
      key: 'name',
      width: 450,
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              {
                data.isEdit
                  ? (<Input style={{ width: 400 }} value={data.editName} onChange={data.onNameChange} />)
                  : <div>{data.name}</div>
              }
            </Row>
          )}
        </Observer>
      )
    },
    {
      title: <Row justify="center">部門電話</Row>,
      key: 'phone',
      width: 450,
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              {
                data.isEdit
                  ? (<Input style={{ width: 400 }} value={data.editPhone} onChange={data.onPhoneChange} />)
                  : <div>{data.phone || '-'}</div>
              }
            </Row>
          )}
        </Observer>
      )
    },
    {
      title: <Row justify="center">最後更新時間</Row>,
      key: 'modifiedDate',
      width: 250,
      render: (data) => (
        <Observer>
          {() => (<Row justify="center">{dayjs(data.modifiedDate).format('YYYY-MM-DD HH:mm')}</Row>)}
        </Observer>
      )
    },
    {
      title: <Row justify="center">操作</Row>,
      key: 'action',
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              {
                data.isEdit
                  ? (
                    <>
                      <Button type="link" onClick={data.update}>儲存</Button>
                      <Button type="link" danger onClick={data.onEditCancel}>取消</Button>
                    </>
                  )
                  : <Button type="link" disabled={data.defaultDepartment} onClick={data.onEdit}>編輯</Button>
              }
            </Row>
          )}
        </Observer>
      )
    }
  ];

  render() {
    return (
      <div>

        <Row justify="end" className={styles.btnRow}>
          <Button type="primary" danger onClick={() => this.vm.deleteModal.show()}>刪除部門</Button>
          <Button type="primary" className={styles.ml} onClick={() => this.vm.createModal.show()}>＋ 新增部門</Button>
        </Row>

        <Table
          size="small"
          rowKey="id"
          columns={this.columns}
          dataSource={this.vm.list}
          pagination={false}
          scroll={{ y: 'calc(100vh - 220px)' }}
        />

        <CreateModal
          modal={this.vm.createModal}
          create={this.vm.create}
        />

        <DeleteModal
          modal={this.vm.deleteModal}
          delete={this.vm.delete}
          list={this.vm.list}
        />

      </div>
    );
  }
}

DepartmentPane.propTypes = {
  vm: PropTypes.object.isRequired
};

export default DepartmentPane;
