import {
  makeObservable, computed, observable, action
} from 'mobx';

export default class ModalViewModel {
  @observable data = null;
  @observable visible = false;

  constructor() {
    makeObservable(this);
  }

  @action
  hide = () => {
    this.visible = false;
    this.data = null;
  }

  @action
  show = (data = null) => {
    this.visible = true;
    if (data) { this.data = data; }
  }
}
