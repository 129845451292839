import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import { Table } from 'antd';

function VirtualTable(props) {
  // lineCount is how many lines in a row
  const { columns, scroll, lineCount } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [rowHight, setRowHight] = useState(0);
  const [mergedColumns, setMergedColumns] = useState([]);

  useEffect(() => {
    // number of columns without default width
    const countColWithoutWidth = columns.filter(({ width }) => !width).length;

    // calculate total width of columns with default width
    let existingWidth = 0;
    columns.forEach((c) => {
      if (c.width) {
        existingWidth += c.width;
      }
    });

    // give width to columns without default width
    const _mergedColumns = columns.map((column) => {
      if (column.width) return column;
      return { ...column, width: Math.floor((tableWidth - (existingWidth)) / (countColWithoutWidth)) };
    });

    setMergedColumns(_mergedColumns);

  }, [columns, tableWidth]);

  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = { value: 0 };
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => obj.value,
      set: (scrollLeft) => {
        obj.value = scrollLeft;
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft
          });
        }
      }
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true
      });
    }
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  useEffect(() => {
    setRowHight(lineCount >= 2 ? (lineCount * 30) : (2 * 30));
  }, [lineCount]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = connectObject;
    return (
      <Grid
        ref={gridRef}
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return width;
        }}
        height={scroll.y - 39} // subtract height of table header
        rowCount={rawData.length}
        rowHeight={() => rowHight}
        width={tableWidth}
        onScroll={({ scrollLeft, scrollTop }) => {
          onScroll({
            scrollLeft,
            scrollTop
          });
          // call fetch when reach last row
          if ((props.dataSource.length * rowHight) - scrollTop - props.scroll.y < rowHight * 1) {
            console.log('Fetch!');
            props.onFetch();
          }
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          return (
            <div
              style={{
                ...style,
                borderBottom: '1px solid #f0f0f0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {
                rawData[rowIndex][mergedColumns[columnIndex].dataIndex] !== undefined
                  ? rawData[rowIndex][mergedColumns[columnIndex].dataIndex]
                  : mergedColumns[columnIndex].render(rawData[rowIndex], null, rowIndex)
              }
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => { setTableWidth(width); }}
    >
      <Table
        key={`ss${tableWidth}`}
        {...props}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList
        }}
      />
    </ResizeObserver>
  );
}

VirtualTable.propTypes = {
  columns: PropTypes.array.isRequired,
  scroll: PropTypes.objectOf(PropTypes.any).isRequired,
  dataSource: PropTypes.array.isRequired,
  lineCount: PropTypes.number,
  onFetch: PropTypes.func
};

VirtualTable.defaultProps = {
  lineCount: 2,
  onFetch: () => {}
};

export default VirtualTable;
