import React, { useState, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Form, Input, Button, message
} from 'antd';

import LayoutBeforeLogin from 'src/components/LayoutBeforeLogin';
import MeService from 'src/services/me';
import styles from './styles.module.css';

const ResetPasswordPage = (props) => {
  const [params] = useSearchParams();
  const [isOk, setIsOk] = useState(false);
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (fields) => {
      try {
        const { password } = fields;
        const resetPasswordToken = params.get('resetPasswordToken');

        await MeService.resetPassword(resetPasswordToken, password);
        setIsOk(true);
        message.success('已重設密碼，請以新密碼重新登入');
        navigate('/login');

      } catch (err) {
        switch (err?.response?.status) {
          case 400:
            message.error('格式錯誤！');
            break;
          default:
            message.error(`發生錯誤：${err?.message}`);
        }
      }
    },
    [navigate, params]
  );

  return (
    <LayoutBeforeLogin>
      <div className={styles.formWrapper}>
        <div className={styles.title}>重設密碼</div>
        {isOk
          ? (
            <div className={styles.message}>
              密碼已重設，請
              <a href="/login">重新登入</a>
            </div>
          )
          : (
            <Form
              name="basic"
              layout="vertical"
              requiredMark={false}
              initialValues={{ remember: false }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="新密碼"
                name="password"
                rules={[{
                  required: true,
                  message: '密碼須包含英文與數字共八碼以上',
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="再次輸入新密碼"
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: '此欄必填!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('與密碼不相符!'));
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  確定
                </Button>
              </Form.Item>


            </Form>
          )}

      </div>
    </LayoutBeforeLogin>
  );
};

export default ResetPasswordPage;

