import Qs from 'qs';
import { request, getHost } from 'src/api';
import AuthService from './auth';

export default class UserService {
  /**
   *
   * @param {Object} data
   * @param {'userType'|'department'|'permissionType'|'accountStatus'|'modifiedDate'} data.order required
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.department
   * @param {string[]} data.customerBelong
   * @param {'manager'|'normal'} data.permission
   * @param {'activate'|'deactivate'} data.status
   * @param {'user'|'customer'} data.type
   */
  static async get(data) {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      params: data
    };
    const res = await request(options);
    const { users, anchor } = res.data;
    return { users, anchor };
  }


  /**
   *
   * @param {string[]} ids
   *
   * @returns {Object[]}
   */
  static async getUserByIds(ids) {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/ids`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      params: { ids: [...ids] },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'repeat' });
      }
    };
    const res = await request(options);
    return res.data.result;
  }



  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.nickname
   * @param {string} data.email
   * @param {string} data.phone
   * @param {string} data.phoneExt
   * @param {string} data.type
   * @param {string} data.permission
   * @param {string} data.password
   * @param {string} data.department
   * @param {string[]} data.customerBelong
   */
  static async create(data) {
    const token = AuthService.getToken();

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data
    };
    return request(options);
  }


  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.nickname
   * @param {string} data.phone
   * @param {string} data.phoneExt
   * @param {string} data.permission
   * @param {string} data.password
   * @param {string} data.department
   * @param {string[]} data.customerBelong
   */
  static async update(id, data) {
    const token = AuthService.getToken();

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/user/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   */
  static async activate(id) {
    const token = AuthService.getToken();

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/${id}/activate`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   */
  static async deactivate(id) {
    const token = AuthService.getToken();

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/${id}/deactivate`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    return request(options);
  }



  /**
   *
   * @param {string} id
   */
  static async customerBelong(id) {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/${id}/customerBelong`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   *
   * @param {string} id
   */
  static async applications(id) {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/${id}/applications`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    const res = await request(options);
    return res.data.applications;
  }



  /**
   *
   * @param {string} id
   */
  static async delete(id) {
    const token = AuthService.getToken();

    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/user/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    return request(options);
  }



  /**
   *
   * @param {Object} data
   * @param {'userType'|'department'|'permissionType'|'accountStatus'|'modifiedDate'} data.order required
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   */
  static async getDeletedUser(data) {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/deletedUser`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      params: data
    };
    const res = await request(options);
    const { users, anchor } = res.data;
    return { users, anchor };
  }
}
