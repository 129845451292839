import { Modal } from 'antd';

export const delay = (ms) => new Promise((r) => setTimeout(r, ms));

/**
 *
 * @param {string} value
 * @param {(string|RegExp)[]} patterns
 * @returns {boolean}
 */
export const some = (value, patterns = []) => {
  return patterns.some((p) => {
    const reg = Object.prototype.toString.call(value) === '[object String]' ? new RegExp(p) : p;
    return reg.test(value);
  });
};

/**
 * array 1D to 2D
 * @param {any[]} arr
 * @param {number} size group size
 * @returns
 */
export const group = (arr, size) => Array.from(
  { length: Math.ceil(arr.length / size) },
  (_, idx) => arr.slice(idx * size, (idx + 1) * size)
);

/**
 *
 * @param {string} ISO ISO time string
 * @returns {string} YYYY=MM-DD HH:MM
 */
export const dateFormater = (time) => {
  if (time && typeof time === 'string' && time.includes('T')) {
    const date = time.split('T')[0];
    const hour = time.split('T')[1].substring(0, 5);
    return `${date} ${hour}`;
  }
  return null;
};

export const errorModal = (title = '', content = '') => {
  return Modal.error({
    title,
    content,
    okText: '確認'
  });
};
