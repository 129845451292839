import React, { useState } from 'react';
import { observer, Observer } from 'mobx-react';
import { Input, Modal, Button, Row } from 'antd';
import Table from 'src/components/VirtualTable';
import dayjs from 'dayjs';
import ReactResizeDetector from 'react-resize-detector';
import { USER_ORDER } from 'src/constants';
import sortActive from 'src/assets/sortActive.png';
import sortDisabled from 'src/assets/sortDisabled.png';
import commonColumns from '../components/commonColumns';

import styles from './styles.module.css';

const Deactivated = observer((props) => {
  const [columns] = useState(() => {
    const _commonColumns = commonColumns(props.vm);
    const _columns = [
      ..._commonColumns,
      {
        title: (
          <Observer>
            {() => (
              <Row justify="center" align="middle">
                <div>停用時間</div>
                <div
                  className={styles.icon}
                  onClick={() => props.vm.onOrderChange(USER_ORDER.MODIFIED_DATE)}
                >
                  {props.vm?.order === USER_ORDER.MODIFIED_DATE
                    ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                    : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
                </div>
              </Row>
            )}
          </Observer>
        ),
        key: 'modifiedDate',
        width: 170,
        render: (data) => (
          <Row justify="center">
            {data.modifiedDate ? dayjs(data.modifiedDate).format('YYYY-MM-DD HH:mm') : ''}
          </Row>
        )
      },
      {
        title: <Row justify="center">操作</Row>,
        key: 'action',
        width: 150,
        render: (data) => (
          <Row justify="center">
            <Button
              type="link"
              onClick={() => props.showActionModal(data)}
            >
              啟用
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: `確定要刪除使用者「 ${data.name} 」？`,
                  content: '此帳號在子系統的資料皆會被刪除，確定要刪除？',
                  okText: '確定',
                  cancelText: '取消',
                  onOk: data.delete
                });
              }}
            >
              刪除
            </Button>
          </Row>
        )
      }
    ];
    return _columns;
  });


  return (
    <div>
      <Input.Search
        placeholder="Email、使用者姓名"
        onSearch={props.vm.onSearch}
        style={{ marginBottom: 10, width: 600 }}
      />

      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <div style={{ width: 1200, height: 'calc(100vh - 170px)' }} id="deactivatedTable">
                <Table
                  size="small"
                  rowKey="_id"
                  onFetch={() => {
                    if (props.vm.anchor) props.vm.getList();
                  }}
                  columns={columns}
                  scroll={{ y: height, x: 1200 }}
                  dataSource={props.vm.list}
                />
              </div>
            )}
          </Observer>
        )}
      </ReactResizeDetector>
    </div>
  );
});

export default Deactivated;
