import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

import UserPaneVM from 'src/viewModels/UserPane';
import DepartmentPaneVM from 'src/viewModels/DepartmentPane';
import ApplicationPaneVM from 'src/viewModels/ApplicationPane';
import ModalViewModel from 'src/viewModels/modal';

export default class MainPageVM {

  userPaneVM = new UserPaneVM(this);
  departmentPaneVM = new DepartmentPaneVM(this);
  applicationPaneVM = new ApplicationPaneVM(this);

  changePasswordModalVM = new ModalViewModel();

  @observable updateProfileModalVM = {
    visible: false,
    show: () => this.toggleeProfileModal(true),
    hide: () => this.toggleeProfileModal(false),
    informUserPaneReload: () => this.informUserPaneReload()
  };

  constructor() {
    makeObservable(this);
  }

  informUserPaneReload = () => {
    this.userPaneVM.reload();
  }

  @action
  toggleeProfileModal = (bool) => {
    this.updateProfileModalVM.visible = bool;
  }
}
