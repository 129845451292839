import { AUTH_TOKEN_KEY } from 'src/constants';

export default class Auth {
  static hasToken() {
    return !!localStorage.getItem(AUTH_TOKEN_KEY);
  }

  static getToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  }

  static removeToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
}
