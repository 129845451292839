import React from 'react';
import { observer } from 'mobx-react';
import { Drawer, Tabs } from 'antd';

import Deactivated from './DeactivatedPane';
import Deleted from './DeletedPane';

const DeletedModal = observer((props) => {
  return (
    <Drawer
      title="停用 / 刪除帳號"
      footer={null}
      onClose={props.vm.hide}
      visible={props.vm.visible}
      width={1250}
      bodyStyle={{ padding: '5px 20px' }}
      maskClosable={false}
      zIndex={100}
    >
      <Tabs
        activeKey={props.vm.avtivePaneKey}
        onTabClick={props.vm.onTabClick}
      >
        <Tabs.TabPane
          tab="停用帳號清單"
          key="1"
        >
          <Deactivated
            vm={props.vm.DeactivatedPaneVM}
            showActionModal={props.showActionModal}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="刪除帳號紀錄"
          key="2"
        >
          <Deleted vm={props.vm.DeletedPaneVM} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
});

export default DeletedModal;
