import { Observer } from 'mobx-react';
import { Button, Tooltip, Row, Spin, Divider } from 'antd';
import { PERMISSION_TYPE_TXT_L, USER_TYPE_TXT_L, USER_TYPE, USER_ORDER } from 'src/constants';

import sortActive from 'src/assets/sortActive.png';
import sortDisabled from 'src/assets/sortDisabled.png';

import styles from '../styles.module.css';

const commonColumns = (vm) => ([
  {
    title: (
      <Observer>
        {() => (
          <Row justify="center" align="middle">
            <div>身份</div>
            <div
              className={styles.icon}
              onClick={() => vm.onOrderChange(USER_ORDER.USER_TYPE)}
            >
              {vm?.order === USER_ORDER.USER_TYPE
                ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
            </div>
          </Row>
        )}
      </Observer>
    ),
    key: 'permission',
    width: 140,
    showSorterTooltip: true,
    render: (data) => (
      <Observer>
        {() => (
          <Row justify="center">{USER_TYPE_TXT_L[data.type]}</Row>
        )}
      </Observer>
    )
  },
  {
    title: (
      <Observer>
        {() => (
          <Row justify="center" align="middle">
            <div>部門(內部人員用)</div>
            <div
              className={styles.icon}
              onClick={() => vm.onOrderChange(USER_ORDER.DEPARTMENT)}
            >
              {vm?.order === USER_ORDER.DEPARTMENT
                ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
            </div>
          </Row>
        )}
      </Observer>
    ),
    key: 'department',
    width: 150,
    render: (data) => (
      <Observer>
        {() => (
          <Row justify="center">
            {data.type !== USER_TYPE.CUSTOMER
              ? <div className={styles.txt}>{data.department.name}</div>
              : <div>-</div>}
          </Row>
        )}
      </Observer>
    )
  },
  {
    title: (
      <Observer>
        {() => (
          <Row justify="center" align="middle">
            <div>本系統權限</div>
            <div
              className={styles.icon}
              onClick={() => vm.onOrderChange(USER_ORDER.PERMISSION_TYPE)}
            >
              {vm?.order === USER_ORDER.PERMISSION_TYPE
                ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
            </div>
          </Row>
        )}
      </Observer>
    ),
    key: 'type',
    width: 140,
    render: (data) => (
      <Observer>
        {() => (
          <Row justify="center">{PERMISSION_TYPE_TXT_L[data.permission]}</Row>
        )}
      </Observer>
    )
  },
  {
    title: <Row justify="center">Email(帳號)</Row>,
    key: 'email',
    width: 230,
    render: (data) => (
      <Row justify="center">
        <div className={styles.txt}>{data.email}</div>
      </Row>
    )
  },
  {
    title: <Row justify="center">使用者姓名</Row>,
    key: 'name',
    width: 170,
    render: (data) => (
      <Observer>
        {() => (
          <Row justify="center">
            <div className={styles.txt}>
              {`${data.name} ${data.nickname ? `(${data.nickname})` : ''}`}
            </div>
          </Row>
        )}
      </Observer>
    )
  },
  {
    title: <Row justify="center">所屬部門(客戶專用)</Row>,
    key: 'customerBelong',
    width: 150,
    render: (data) => (
      <Observer>
        {() => (
          <Row justify="center">
            {data.type === USER_TYPE.CUSTOMER
              ? (
                <Tooltip
                  color="white"
                  onMouseEnter={data.getCurtomerBelong}
                  title={(
                    <div
                      style={{ color: 'black', width: 150 }}
                    >
                      <div>所屬部門(客戶專用)</div>
                      <Divider style={{ margin: '5px 0' }} />
                      {data.isLoading
                        ? (<Spin />)
                        : (
                          <>
                            {data.customerBelongUI.map((a) => (
                              <div key={a.id}>{a.name}</div>
                            ))}
                          </>
                        )}
                    </div>
            )}
                >
                  <Button type="link">{'> more ...'}</Button>
                </Tooltip>
              )
              : <div>-</div>}
          </Row>
        )}
      </Observer>
    )
  },
  {
    title: <Row justify="center">可使用系統</Row>,
    key: 'applications',
    width: 120,
    render: (data) => (
      <Observer>
        {() => (
          <Row justify="center">
            <Tooltip
              color="white"
              onMouseEnter={data.getApplications}
              title={(
                <div
                  style={{ color: 'black', width: 150 }}
                >
                  <div>可使用系統</div>
                  <Divider style={{ margin: '5px 0' }} />
                  {data.isLoading
                    ? (<Spin />)
                    : (
                      <>
                        {data.applications.map((a) => (
                          <div key={a.id}>{a.name}</div>
                        ))}
                      </>
                    )}
                </div>
            )}
            >
              <Button type="link">{'> more ...'}</Button>
            </Tooltip>
          </Row>
        )}
      </Observer>
    )
  }
]);


export default commonColumns;
