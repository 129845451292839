import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message, Row } from 'antd';
import queryString from 'query-string';

import LayoutBeforeLogin from 'src/components/LayoutBeforeLogin';
import MeService from 'src/services/me';
import styles from './styles.module.css';

const ForgetPasswordPage = (props) => {
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (fields) => {
      try {
        const { email } = fields;
        await MeService.resetPasswordRequest(email);

        message.success('信件已送出');

        const { redirect } = queryString.parse(window.location.search);
        if (redirect) {
          window.location = redirect;
        } else {
          navigate('/login');
        }

      } catch (err) {
        switch (err?.response?.status) {
          case 401:
            message.error('此帳號無權登入!');
            break;

          default:
            message.error(`發生錯誤：${err?.message}`);
        }
      }
    },
    [navigate]
  );

  const onBack = useCallback(
    async (fields) => {
      const { redirect } = queryString.parse(window.location.search);
      if (redirect) {
        window.location = redirect;
      } else {
        navigate('/login');
      }
    },
    [navigate]
  );

  return (
    <LayoutBeforeLogin>
      <div className={styles.formWrapper}>
        <div className={styles.title}>忘記密碼</div>
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          autoComplete="off"
        >

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Email 格式有誤！'
              }
            ]}
          >
            <Input placeholder="請輸入您的email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              寄發修改密碼連結
            </Button>
          </Form.Item>


          <Form.Item>
            <Row justify="end">
              <Button type="link" onClick={onBack}>
                返回登入頁
              </Button>
            </Row>
          </Form.Item>

        </Form>
      </div>
    </LayoutBeforeLogin>
  );
};

export default ForgetPasswordPage;

