import { request, getHost } from 'src/api';
import AuthService from './auth';

export default class DepartmentService {
  static async get() {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.phone
   */
  static async create(data) {
    const token = AuthService.getToken();

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   *
   * @param {string} id
   */
  static async delete(id) {
    const token = AuthService.getToken();

    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/department/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    return request(options);
  }


  /**
   * @param {string} id
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.phone
   */
  static async update(id, data) {
    const token = AuthService.getToken();

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/department/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data
    };
    return request(options);
  }
}
