import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

import DeletedPaneModalViewModel from './DeletedPane';
import DeactivatedPaneViewModel from './DeactivatedPane';

export default class DeletedModalViewModel {
  @observable visible = false;
  @observable avtivePaneKey = '1';

  DeletedPaneVM = new DeletedPaneModalViewModel();
  DeactivatedPaneVM = new DeactivatedPaneViewModel(this);

  @observable userPaneNeedReload = false;
  userPaneRelaod = () => {};

  constructor(userPaneRelaod) {
    makeObservable(this);
    this.userPaneRelaod = userPaneRelaod;
  }

  @action
  show = () => {
    this.visible = true;
    this.userPaneNeedReload = false;
    this.DeletedPaneVM.getList();
    this.DeactivatedPaneVM.getList();
  }

  @action
  hide = () => {
    this.visible = false;
    this.avtivePaneKey = '1';
    this.DeletedPaneVM.reset();
    this.DeactivatedPaneVM.reset();

    if (this.userPaneNeedReload) {
      this.userPaneRelaod();
    }
  }

  informDelete = () => {
    this.DeletedPaneVM.reload();
    this.userPaneNeedReload = true;
  }

  @action
  onTabClick= (key) => {
    this.avtivePaneKey = key;
  }

}
