import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Form, Modal, Select } from 'antd';


const DeleteModal = observer((props) => {
  const [form] = Form.useForm();

  const hide = (e) => {
    form.resetFields();
    props.modal.hide();
  };

  const submit = async () => {
    try {
      await form.validateFields();
      const { id } = form.getFieldsValue();
      const name = props.list.find((dep) => dep.id === id).name;
      props.delete(id, name);
      form.resetFields();
    } catch {
      // ignore
    }
  };

  return (
    <Modal
      visible={props.modal.visible}
      title="選擇要刪除的部門"
      okText="刪除"
      cancelText="取消"
      okButtonProps={{ danger: true }}
      onCancel={hide}
      onOk={submit}
      maskClosable={false}
    >
      <Form form={form}>
        <Form.Item
          label="部門名稱"
          name="id"
          rules={[{ required: true, message: '此欄必填!' }]}
        >
          <Select>
            {props.list.map((department) => (
              <Select.Option key={department.id} value={department.id}>{department.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  );
});

export default DeleteModal;
