import React from 'react';
import dayjs from 'dayjs';
import { observer, Observer } from 'mobx-react';
import { Button, Row } from 'antd';
import PropTypes from 'prop-types';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_TXT_L, USER_ORDER } from 'src/constants';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import { AppStateContext } from 'src/stores';
import sortActive from 'src/assets/sortActive.png';
import sortDisabled from 'src/assets/sortDisabled.png';
import SearchBar from './SearchBar';
import ActionModal from './ActionModal';
import DeletedModal from './DeletedModal';
import commonColumns from './components/commonColumns';

import styles from './styles.module.css';

@observer
class UserPane extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = this.props.vm;


    const _commonColumns = commonColumns(this.vm);
    this.columns = [
      ..._commonColumns,
      {
        title: <Row justify="center">部門電話 #分機</Row>,
        key: 'phone',
        width: 170,
        render: (data) => (
          <Observer>
            {() => (
              <Row justify="center">
                <div className={styles.txt}>
                  {`${data.phone ? data.phone : '-'} ${data.phoneExt ? `#${data.phoneExt}` : ''}`}
                </div>
              </Row>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <Row justify="center" align="middle">
                <div>狀態</div>
                <div
                  className={styles.icon}
                  onClick={() => this.vm.onOrderChange(USER_ORDER.ACCOUNT_STATUS)}
                >
                  {this.vm?.order === USER_ORDER.ACCOUNT_STATUS
                    ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                    : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
                </div>
              </Row>
            )}
          </Observer>
        ),
        key: 'status',
        width: 120,
        render: (data) => (
          <Observer>
            {() => (
              <Row justify="center" align="middle">
                <div
                  className={styles.dot}
                  style={{
                    background: data.status === ACCOUNT_STATUS.ACTIVATE ? '#52C41A' : '#FF4D4F'
                  }}
                />
                <div>{ACCOUNT_STATUS_TXT_L[data.status]}</div>
              </Row>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">建立者</Row>,
        key: 'creator',
        width: 170,
        render: (data) => (
          <Observer>
            {() => (
              <Row justify="center">
                <div className={styles.txt}>
                  {data.creator?.name ?? '-'}
                </div>
              </Row>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <Row justify="center" align="middle">
                <div>最後更新時間</div>
                <div
                  className={styles.icon}
                  onClick={() => this.vm.onOrderChange(USER_ORDER.MODIFIED_DATE)}
                >
                  {this.vm?.order === USER_ORDER.MODIFIED_DATE
                    ? <img width="16" height="13" src={sortActive} alt="sortActive" />
                    : <img width="16" height="13" src={sortDisabled} alt="sortDisabled" />}
                </div>
              </Row>
            )}
          </Observer>
        ),
        key: 'modifiedDate',
        width: 170,
        render: (data) => (
          <Observer>
            {() => (
              <Row justify="center">{data.modifiedDate ? dayjs(data.modifiedDate).format('YYYY-MM-DD HH:mm') : ''}</Row>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">操作</Row>,
        key: 'action',
        width: 150,
        render: (data) => (
          <Observer>
            {() => (
              <Row justify="center">
                <Button
                  type="link"
                  disabled={data.status === ACCOUNT_STATUS.DEACTIVATE}
                  onClick={() => this.vm.modal.show(data)}
                >
                  編輯
                </Button>
                <Button
                  danger
                  type="link"
                  disabled={
                    (data.status === ACCOUNT_STATUS.DEACTIVATE)
                    || (this.context.state.profile.id === data.id)
                  }
                  onClick={data.deactivate}
                >
                  停用
                </Button>
              </Row>
            )}
          </Observer>
        )
      }
    ];
  }

  componentDidMount() {
    this.vm.didMount();
  }


  render() {
    return (
      <div>
        <Row justify="end">
          <Button type="primary" className={styles.mr} onClick={() => this.vm.modal.show()}>
            + 新增使用者
          </Button>
          <Button type="dash" onClick={this.vm.deletedModalVm.show}>
            瀏覽 停用／刪除 帳號
          </Button>
        </Row>

        <SearchBar
          onSearch={this.vm.onSearch}
          innerDepartment={this.vm.innerDepartment}
          clientDepartment={this.vm.clientDepartment}
        />

        <ReactResizeDetector handleHeight>
          {({ height = 0 }) => (
            <Observer>
              {() => (
                <div style={{ maxWidth: 1880, height: 'calc(100vh - 300px)' }}>
                  {(this.vm) && (
                    <Table
                      size="small"
                      rowKey="_id"
                      onFetch={() => {
                        if (this.vm.anchor) this.vm.getList();
                      }}
                      columns={this.columns}
                      scroll={{ y: height, x: 1880 }}
                      dataSource={this.vm.list}
                    />
                  )}
                </div>
              )}
            </Observer>
          )}
        </ReactResizeDetector>


        <DeletedModal
          vm={this.vm.deletedModalVm}
          showActionModal={this.vm.modal.show}
        />


        <ActionModal
          modal={this.vm.modal}
          clientDepartment={this.vm.clientDepartment}
          departmentPhoneIdMap={this.vm.departmentPhoneIdMap}
          create={this.vm.create}
        />

      </div>
    );
  }
}

UserPane.propTypes = {
  vm: PropTypes.object.isRequired
};

export default UserPane;
