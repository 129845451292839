import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, message } from 'antd';
import PropTypes from 'prop-types';

import AuthService from 'src/services/auth';
import MeService from 'src/services/me';
import useAppStateContext from 'src/stores/global/useAppStateContext';
import styles from './styles.module.css';

const LayoutBeforeLogin = (props) => {
  const navigate = useNavigate();
  const { state, actions } = useAppStateContext();
  const { updateProfile } = actions ?? {};

  useEffect(
    () => {
      const getProfile = async () => {
        return MeService.getProfile;
      };

      if (AuthService.hasToken()) {
        try {
          const p = getProfile();
          navigate('/');
          updateProfile(p);

        } catch (err) {
          switch (err?.response?.status) {
            case 401:
              message.error('此帳戶無權登入!');
              break;

            default:
              message.error(`發生錯誤：${err?.message}`);
          }
        }
      }
    },
    [navigate, updateProfile]
  );

  return (
    <div className={styles.page}>
      <Layout>

        <Layout.Header className={styles.header}>
          <div className={styles.headerLeft}>i-Buzz 帳號管理系統</div>
        </Layout.Header>

        <Layout.Content className={styles.content}>
          {props.children}
        </Layout.Content>
      </Layout>
    </div>
  );
};



LayoutBeforeLogin.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutBeforeLogin;

