import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Table } from 'antd';

@observer
class ApplicationPane extends React.Component {

  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Table
          size="small"
          rowKey="_id"
          dataSource={this.vm.list}
          pagination={false}
          scroll={{ y: 'calc(100vh - 170px)' }}
        >

          <Table.Column
            title="應用系統名稱"
            key="name"
            render={(data) => (
              <Button type="link" onClick={() => window.open(data.landingUrl)}>
                {data.name}
              </Button>
            )}
          />

        </Table>

      </>
    );

  }
}

ApplicationPane.propTypes = {
  vm: PropTypes.object.isRequired
};

export default ApplicationPane;
