import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ApplicationService from 'src/services/application';
import { errorModal } from 'src/utils';


export default class ApplicationPane {

  @observable list = [];

  mainPageVM = null;

  constructor(mainPageVM) {
    makeObservable(this);
    this.mainPageVM = mainPageVM;
  }

  didMount = () => {
    this.getList();
  }

  getList = async () => {
    try {
      const res = await ApplicationService.get();
      runInAction(() => { this.list = res; });
    } catch {
      errorModal('發生錯誤，無法取得應用系統列表');
    }
  }

}
