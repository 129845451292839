import { request, getHost } from 'src/api';
import { AUTH_TOKEN_KEY } from 'src/constants';
import AuthService from './auth';

export default class MeService {

  static async getProfile() {
    const token = AuthService.getToken();

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/me`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }



  static async login(data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/login`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data
    };

    const res = await request(options);
    const { accessToken } = res.data;

    localStorage.setItem(AUTH_TOKEN_KEY, accessToken ?? null);
  }



  static async logout() {
    try {
      const token = AuthService.getToken();
      AuthService.removeToken();

      if (token) {
        const options = {
          method: 'post',
          url: `${getHost()}/api/v1/user/logout`,
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${token}`
          }
        };
        await request(options);
      }
    } catch (err) {
      // ignore
    }
  }



  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.nickname
   * @param {string} data.phone
   * @param {string} data.phoneExt
   */
  static async update(data) {
    const token = AuthService.getToken();

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/user/me`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data
    };
    return request(options);
  }



  /**
   *
   * @param {string} password
   */
  static async changePassword(password) {
    const token = AuthService.getToken();

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/user/me/password`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data: { password }
    };
    return request(options);
  }



  /**
   *
   * @param {string} token
   * @param {string} password
   */
  static async resetPassword(token, password) {

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/user/me/resetPassword`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data: { token, password }
    };
    return request(options);
  }



  /**
   *
   * @param {string} email
   */
  static async resetPasswordRequest(email) {

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/user/me/resetPasswordRequest`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data: { email }
    };
    return request(options);
  }
}
