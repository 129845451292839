import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

import { errorModal } from 'src/utils';
import { LIMIT, USER_ORDER } from 'src/constants';
import UserService from 'src/services/user';

export default class DeletedPaneViewModel {
  @observable list = [];
  @observable anchor = null;
  @observable order = USER_ORDER.USER_TYPE;
  @observable keyword = '';
  @observable isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action
  reset = () => {
    this.keyword = '';
    this.list = [];
    this.anchor = null;
    this.order = USER_ORDER.USER_TYPE;
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { users, anchor } = await UserService.getDeletedUser({
        keyword: this.keyword || undefined,
        limit: LIMIT,
        anchor: this.anchor || undefined,
        order: this.order
      });

      runInAction(() => {
        this.list = [...this.list, ...users];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法獲取已刪除使用者列表');
    }
  }

  @action
  onSearch = (keyword) => {
    this.keyword = keyword;
    this.reload();
  }

  @action
  onOrderChange = (newOrder) => {
    if (newOrder === this.order) {
      this.order = USER_ORDER.USER_TYPE;
    } else {
      this.order = newOrder;
    }
    this.reload();
  }

  @action
  reload = () => {
    this.list = [];
    this.anchor = null;
    this.getList();
  }
}
