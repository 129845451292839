import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { Modal } from 'antd';

import { errorModal } from 'src/utils';
import ModalViewModel from 'src/viewModels/modal';
import DepartmentService from 'src/services/department';
import DepartmentItem from './DepartmentItem';

export default class DepartmentPane {

  @observable _list = [];

  createModal = new ModalViewModel();
  deleteModal = new ModalViewModel();
  mainPageVM = null;

  constructor(mainPageVM) {
    makeObservable(this);
    this.mainPageVM = mainPageVM;
  }

  didMount = () => {
    this.getList();
  }

  getList = async () => {
    try {
      const res = await DepartmentService.get();
      const _list = res.map((r) => new DepartmentItem(r, this));
      runInAction(() => { this._list = _list; });
    } catch {
      errorModal('發生錯誤，無法獲取部門列表');
    }
  }

  create = async (data) => {
    try {
      const res = await DepartmentService.create(data);
      const newItem = new DepartmentItem(res, this);

      runInAction(() => { this._list = [...this._list, newItem]; });
      this.createModal.hide();
      this.mainPageVM.informUserPaneReload();

    } catch (err) {
      switch (err?.response?.status) {
        case 409:
          errorModal('部門名稱已存在');
          break;

        default:
          errorModal('發生錯誤，無法新增部門');
      }
    }
  }

  delete = async (id, name) => {
    Modal.confirm({
      title: `確定要刪除部門「 ${name} 」？`,
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        try {
          await DepartmentService.delete(id);
          const newList = this._list.filter((department) => department.id !== id);

          runInAction(() => { this._list = newList; });
          this.deleteModal.hide();
          this.mainPageVM.informUserPaneReload();
          Modal.success({ okText: '確定', title: `已刪除部門「 ${name} 」。` });
        } catch (err) {
          switch (err?.response?.status) {
            case 400:
              errorModal('請將此部門所屬人員轉移至其他部門，才可刪除');
              break;

            default:
              errorModal('發生錯誤，無法刪除部門');
          }
        }
      }
    });
  }

  @computed
  get list() {
    return this._list.filter((department) => !department.defaultDepartment);
  }
}
