import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AppStateContext } from 'src/stores';

import MainPage from './MainPage';
import LoginPage from './LoginPage';
import ForgetPasswordPage from './ForgetPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import NotFoundPage from './NotFoundPage';

class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/login" element={<LoginPage context={this.context} />} />
          <Route path="/forget" element={<ForgetPasswordPage context={this.context} />} />
          <Route path="/forgotPassword" element={<ResetPasswordPage context={this.context} />} />
          <Route path="/" element={<MainPage context={this.context} />} />
          <Route path="*" element={<NotFoundPage context={this.context} />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default PagesRoot;
