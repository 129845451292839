import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form, Input, Button, message, Row
} from 'antd';

import LayoutBeforeLogin from 'src/components/LayoutBeforeLogin';
import MeService from 'src/services/me';
import styles from './styles.module.css';

const LoginPage = (props) => {
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (fields) => {
      try {

        await MeService.login(fields);
        navigate('/');

      } catch (err) {
        switch (err?.response?.status) {
          case 401:
            message.error('無法登入!');
            break;

          default:
            message.error(`發生錯誤：${err?.message}`);
        }
      }
    },
    [navigate]
  );

  return (
    <LayoutBeforeLogin>
      <div className={styles.formWrapper}>
        <div className={styles.title}>登入</div>
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Email 格式有誤！'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密碼"
            name="password"
            rules={[
              {
                type: 'string',
                required: true,
                message: '密碼至少 8 個字元！',
                pattern: /^.{8,}$/
              }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Row justify="end">
              <Button type="link" onClick={() => navigate('/forget')}>
                忘記密碼
              </Button>
            </Row>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              登入
            </Button>
          </Form.Item>


        </Form>
      </div>
    </LayoutBeforeLogin>
  );
};

export default LoginPage;

