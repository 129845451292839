import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

import { errorModal } from 'src/utils';
import { LIMIT, USER_ORDER, ACCOUNT_STATUS } from 'src/constants';
import UserService from 'src/services/user';
import UserItem from '../UserItem';

export default class DeactivatedPaneViewModel {
  @observable list = [];
  @observable anchor = null;
  @observable order = USER_ORDER.USER_TYPE;
  @observable keyword = '';
  @observable isLoading = false;

  deletedModalVm = null;

  constructor(deletedModalVm) {
    makeObservable(this);
    this.deletedModalVm = deletedModalVm;
  }

  @action
  reset = () => {
    this.keyword = '';
    this.list = [];
    this.anchor = null;
    this.order = USER_ORDER.USER_TYPE;
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { users, anchor } = await UserService.get({
        keyword: this.keyword || undefined,
        status: ACCOUNT_STATUS.DEACTIVATE,
        limit: LIMIT,
        anchor: this.anchor || undefined,
        order: this.order
      });

      const list = users.map((u) => new UserItem(u, this.delete));

      runInAction(() => {
        this.list = [...this.list, ...list];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法獲取已停用使用者列表');
    }
  }

  @action
  onSearch = (keyword) => {
    this.keyword = keyword;
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  @action
  onOrderChange = (newOrder) => {
    if (newOrder === this.order) {
      this.order = USER_ORDER.USER_TYPE;
    } else {
      this.order = newOrder;
    }
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  @action
  delete = (id) => {
    this.list = this.list.filter((item) => item.id !== id);
    this.deletedModalVm.informDelete();
  }
}
